import moment from "moment";

export const ChartTooltipContent = ({
                                        x, distance, points, unit, units,
                                        valueFormatter, valueFormatters, timeFormat
                                    }: any) => {
    valueFormatter ??= (x: number) => x.toFixed(1);
    timeFormat ??= "H:mm:ss";
    let distanceValue = null
    if (distance != null) {
        if (distance < 1)
            distanceValue = `${(distance * 1000).toFixed(0)} m`
        else
            distanceValue = `${distance.toFixed(3)} km`
    }
    return <>
        <div>
            {String(moment(x).utc().format(timeFormat))}
            {distanceValue != null && <>
                <br/>
                {distanceValue}
            </>}
        </div>
        <div style={{marginLeft: 10}}>
            {points?.map((point: any, i: number) => {
                const name = point.name ? `${point.name}: ` : undefined
                const value = (valueFormatters ? valueFormatters[i] : valueFormatter)(point.y);
                const unitValue = units ? units[i] : unit;
                return <div key={i} style={{color: point.color, fontWeight: 700}}>
                    {name}{value} {unitValue}
                </div>;
            })
            }
        </div>
    </>
}